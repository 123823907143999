.page-state-positions.article {
    background: linear-gradient(180deg, var(--color-gradientA) 450px, var(--color-gradientB) 450px);

    .header {
        padding: 0 135px;
        align-items: flex-start;
         .divider {
            width: 100%;
            border-bottom: 1px solid var(--color-border);
            margin-bottom: 25px;
            margin-top: 20px;
        }

        .endorsed {
            justify-content: flex-start;
        }
    }

    >h1 {
        border-bottom: 1px solid var(--color-border);
        padding-bottom: 40px;
        max-width: 650px;
        width: 100%;
        margin-bottom: 40px;

        @media (max-width: $tablet) {
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }

    .body {
        background: var(--color-background);
        padding: 60px 135px;
        box-sizing: border-box;

        @media (max-width: $tablet) {
            padding: 40px;

     
        }
    }

    .references {
        background: var(--color-background);

        ol {
            list-style: auto;
            font-size: 16px;

            a, p, span {
                font-size: 16px;
            }

            li {
                margin-bottom: 10px;
                line-height: 18px;
            }
        }
        
        .inner {
            padding: 40px 0;
            border-top: 1px solid var(--color-border);
            border-bottom: 1px solid var(--color-border);

            @media (max-width: $tablet) {
                padding: 40px;
            }
        }

        p {
            font-size: 12px;
        }

        a {
            color: var(--color-text);
        }
    }

    .other-states {
        background: var(--color-background);

        .custom-select {
            width: 300px;
            max-width: 300px;
            min-width: 300px;
        }

        .inner {
            padding: 70px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: $tablet) {
                flex-direction: column;  
            }
        }

        h2 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 1.8rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 0;
            margin-right: 20px;
            letter-spacing: -0.01em;

            @media (max-width: $tablet) {
                margin-bottom:20px;
            }
        }
    }
}


.page-state-positions {
    background: linear-gradient(180deg, var(--color-gradientA) 450px, var(--color-gradientB) 450px);

    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column; 
        padding: 0;

        @media (max-width: $tablet) {
            padding: 30px !important;
        }

        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            
            span {
                &.flag {
                    width: 150px;

                    
                }
                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        p.endorsed {
            font-size: 1rem;
            text-transform: uppercase;
            color: var(--color-text);
            font-weight: 400;
            margin: 0 0 30px 0;
            width: 100%;
            max-width: 650px;
            display: flex;
            align-items: center;

           justify-content: center;
  

            svg {
                margin-right: 20px;
                width: 50px;
                height: 50px;
                
                path { fill: var(--color-text); }
            }
        }

        .intro {
            background: var(--color-background);
            padding: 30px 135px;
            margin-top: 60px;
            box-sizing: border-box;

            @media (max-width: $tablet) {
                padding: 40px 80px;
                width: 90%;
            }
        }
    }

    .search-box {
        svg {
            top:2px;

            @media (max-width: $tablet) {
                top: 11px;
            }
        }
    }

    .filter {
        display: flex;
        margin-top: 2px;

        .search-box {
            @media (max-width: $tablet) {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                margin-bottom: 10px;

                input[type="text"] {
                    width: 100%;
                    max-width: 100%;
                    min-width: 100%;
                }
            }
        }

        h2, input[type="text"], label {
            width: 50%;
        }
        .custom-select { 
            width: 300px;
            max-width: 300px;
            min-width: 300px;

            @media (max-width: $tablet) {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                margin-bottom: 10px;
            }
        }

        flex-wrap: wrap;

        h2 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 1.8rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 20px;
            letter-spacing: -0.01em;
        }

        .inner {
            width: 100%;
            display: flex;
            padding: 50px 0;

            @media (max-width: $tablet) {
               flex-direction: column;
               justify-content: center;
               
               padding: 30px;
               padding-top: 0;
            }
        }

        .left, .right {
            width: 50%;

            @media (max-width: $tablet) {
                width: 100%;
                text-align: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            height: auto;
            justify-content: space-between;

            @media (max-width: $tablet) {
                width: 100%;
                text-align: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            label {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                @media (max-width: $tablet) {
                    justify-content: center;
                }

                span {
                    margin-right: 20px;
                    font-size: 1rem;
                    text-transform: uppercase;
                    color: var(--color-text);
                    font-weight: 400;
                    margin: 0 20px 0 0;
                    letter-spacing: -0.01em;

                    @media (max-width: $tablet) {
                        display: none;
                    }
                }
            }
        }
    }

    .state-positions-index {
        padding: 40px;
        padding-top: 0;
        display: flex;
        justify-content: space-between;

        
        @media (max-width: $tablet) {
            padding: 30px;
            padding-top: 0;
        }

        .inner {
            width: 100%;
            border-top: 1px solid var(--color-border);
            padding-top: 40px;
            display: flex;
            flex-wrap: wrap;
        }
        
        .country-card {
            width: 32%;
            margin-right: 2%;
            background: var(--color-background-country-flag);
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            overflow: hidden;

            @media (max-width: $tablet) {
                width: 100%;
                margin: 0 0 20px 0;
            }

            img {
                transform: scale(1.08);
                position: relative;
                top: 3px;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }

            div {
                &:first-child {
                    width: 30%;
                }

                &:last-child {
                    width: 70%;
                    padding: 20px;
                    box-sizing: border-box;

                    a {
                        text-transform: uppercase;
                        color: var(--color-text);
                        font-size: 1rem;
                        line-height: 1em;
                        font-weight: 300;
                        margin-bottom: 20px;
                        letter-spacing: 0.03em;
                        text-decoration: none;

                        @media (max-width: $tablet) {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    }
    
}
