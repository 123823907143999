.navigation {
    padding: 20px;
    background: var(--color-nav-background);
    -webkit-box-shadow: 0px 1px 5px 2px rgba(0,0,0,.1);
    box-shadow: 0px 1px 5px 2px rgba(0,0,0,.1);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transition: 0.3s ease;

    .searchbox-header {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        max-height: 0;
        transition: .5s ease;

        &.open {
            max-height: 50px;
        }
    }

    .nav-logo {
        @media (max-width: $tablet) {
            max-width: 80px;
        }
    }

    .nav-social {
        @media (max-width: $tablet) {
            display: none !important;
        }
    }

    .nav-link-about {
        width: 100px;
        cursor: pointer;

        svg {
            position: absolute;
            right: 0;
            top: 4px;
        }
    }

    &.trans {
        background: none;
        -webkit-box-shadow:none;
        box-shadow: none;
    }

    &.hidden {
        transform: translateY(-110%);
        transition: 0.5s ease;
    }

    .mobile-menu-lines {
        stroke: var(--color-fill-icon);
    }

    
    ul {
        display: flex;
        justify-content: space-between;
        max-width: $siteWidth;
        margin: auto;

        li {
            font-size: .8rem;
            margin: 0 1rem 0 0;
            font-family: astoria-sans-condensed, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            position: relative;

            a {
                color: var(--color-text-nav);
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 0.06em;

                &:hover {
                    opacity: 0.5;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &.expanded {

                > a {
                    color: var(--color-nav-link-active) !important;

                    &:hover {
                        color: var(--color-nav-link-active) !important;
                    }
                }

                .sub-menu {
                    display: block;
                }
            }

            .sub-menu {
                position: absolute;
                width: 150px;
                border-radius: 8px;
                left: -10px;
                top: 0;
                padding-top: 30px;
                display: none;


                &:after {
                    content: " ";
                    position: absolute;
                    bottom: 0;
                    height: 90px;
                    width: 100%;
                    box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.1);
                    z-index: -1;
                    border-radius: 10px;
                }

                >div {
                    padding: 30px 20px;
                    border-radius: 10px;
                    background: var(--color-nav-background);
                }

                a {
                    color: var(--color-nav-link);
                    margin-bottom: 15px;
                    display: block;

                    &:hover {
                        color: var(--color-nav-link-active);
                        opacity: 1;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            ol {
                justify-content: center;
                align-items: center;
                display: flex;
                align-content: center;
                height: 100%;

                .nav-item {
                    @media (max-width: $tablet) {
                        display: none;
                    }
                }

                .nav-link {
                    opacity: var(--opacity-nav-link);
                    color: var(--color-nav-link);
                    position: relative;

                    &:hover {
                        color: var(--color-nav-link-active);
                    }

                    &.active {
                        opacity: 1;
                        color: var(--color-nav-link-active);

                        span {
                            width: 100%;
                            transition: 0.3s ease;
                        }
                    }

                    span {
                        position: absolute;
                        background: #FFD256;
                        width: 100%;
                        height: 3px;
                        left: 0;
                        bottom: -5px;
                        width: 0;
                        transition: 0.3s ease;
                    }
                }

                > li {
                    margin-right: 2rem;
                }

                li:last-child {
                    margin-right: 0;
                }

                .controls {
                    display: flex;
                    //width: 190px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .nav-mobile-burger {
                        display: none;
                
                        @media (max-width: $tablet) {
                            display: flex;
                        }
                
                    }

                    @media (max-width: $tablet) {
                        justify-content: flex-end;
                    }

                    path, circle {
                        fill: var(--color-fill-icon);
                    }

                    .stroke {
                        fill: var(--color-fill-icon);
                        stroke: var(--color-fill-icon);
                    }

                    span {
                        border-right: 1px solid var(--color-border);
                        height: 40px;
                        align-items: center;
                        display: flex;
                        padding-right: 10px;
                        padding-left: 10px;
                        
                        &:last-child {
                            border-right: none;
                            padding-right: 0;
                        }

                        &:first-child {
                            @media (max-width: $tablet) {
                                display: none;
                            }
                        }
                    }
                    
                    button {
                        background: none;
                        border: none;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
}
