
.carousel {

    background: var(--color-secondaryBackground);

    img {
      display: none;

      @media (max-width: $tablet) {
        max-width: 100%;
        margin-top: 30px;
        display: block;
      }
    }
    

    .flickity-button {
      display: none;
    }

    .flickity-page-dots {
      width: 50px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -50px;

      @media (max-width: $tablet) {
        display: none;
      }

      button {
        background: none;
        border: none;
        border-radius: 50%;
        width: 9px;
        height: 9px;
        overflow: hidden;
        padding: 0;
        margin: 5px 0;
        position: relative;
        cursor: pointer;

        &:after {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          background: #CAD1E1;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        &.is-selected {
          &:after {
            background: $buttonPrimary;
          }
        }
      }
    }

    .carousel-cell {
      width: 100%; /* full width */
      height: 100%; /* height of carousel */
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      flex-direction: column;
      padding: 50px 0 0 0;

      @media (max-width: $tablet) {
        justify-content: flex-start;
      }

      .image-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: 45%;
        background-repeat: no-repeat;
        background-position: right;
      }

      article {
        max-width: $siteWidth;
        width: 100%;

        h2 {
          text-transform: uppercase;
          color: var(--color-text);
          font-size: 2.5rem;
          line-height: 1em;
          font-weight: 300;
          margin-bottom: 10px;
          letter-spacing: -0.01em;
          margin-bottom: 5px !important;
        }

        p {
          margin-bottom: 40px;
        }

        div {
          width: 50%;
          padding: 0 50px;
          box-sizing: border-box;

          @media (max-width: $tablet) {
            padding: 0 30px;
          }
        }

        a {
          position: relative;
          z-index: 9999;
        }
      }
    }
}

.news-carousel {
    height: 630px;

    @media (max-width: $tablet) {
      padding-left: 30px;
    }

    .flickity-page-dots {
      display: none;
    }

    .flickity-button {
      display: none;
    }
   
    .carousel-cell {
      width: calc($siteWidth / 3);
      height: 100%; /* height of carousel */
      margin-right: 10px;
      //display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      flex-direction: column;
  
      article {
        max-width: $siteWidth;
        display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

        img {
          max-width: 100%;
        }

        button {
          padding: 8px 20px;
        }
      }
    }
}

.issue-carousel {
    background: $backgroundPanelColorSecondary;
    overflow: hidden;

    .flickity-page-dots {
      display: none;
    }

    .flickity-viewport {
      height: 620px !important;
    }

    .flickity-button {
      display: none;
    }
    .carousel-cell {
      width: 100%; /* full width */
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      flex-direction: column;

      .image-background {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: right;
        background-size: 50%;
      }

      .slider-controls {
        margin-top: 60px;
        flex-direction: row !important;
        justify-content: flex-start !important;

        button {
          margin-right: 5px;
        }
      }
  
      article {
        max-width: $siteWidth;
        width: 100%;
        display: flex;
        margin-top: 60px;
        height: 560px;

        @media (max-width: $tablet) {
          margin-top: 0;
       }

        div {
          width: 50%;

          @media (max-width: $tablet) {
            width: 100%;
            padding: 30px;
          }

          &:first-child {
            padding-right: 0;
            box-sizing: border-box;
            padding-right: 100px;

            @media (max-width: $tablet) {
              padding-right: 0px;
            }
          }

          &:last-child {
            display: flex;
            flex-direction: column;
            align-items: self-end;
            justify-content: flex-end;

            @media (max-width: $tablet) {
              display: none;
            }
          }
        }

        img {
          max-width: 118%;
          position: relative;
          right: -40px;
        }

        p, h3, h4, h5, h6 {
          color: $borderAccentColor;
          font-weight: 300;
          margin: 20px 0;
          font-size: 18px;
          line-height: 1.3em;
          letter-spacing: 0;
        }

        h3, h4, h5, h6 {
          font-size: 18px;
          font-weight: 400;
          line-height: 1.3em;
        }

        h2 {
          text-transform: uppercase;
          color: $textColorSecondary;
          font-size: 3rem;
          line-height: 1em;
          font-weight: 300;
          margin-bottom: 10px;
          letter-spacing: -0.01em;

          span {
            display: inline-block;
            border-right: 1px solid var(--color-border);
            padding-right: 20px;
            margin-right: 15px;
            line-height: 40px;

            &:last-child {
              border: none;
            }
          }
        }
      }
    }

}

.flickity-enabled {
    position: relative;
  }
  
  .flickity-enabled:focus { outline: none; }
  
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* draggable */
  
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  
  /* ---- flickity-button ---- */
  
  .flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
  }
  
  .flickity-button:hover {
    background: white;
    cursor: pointer;
  }
  
  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19F;
  }
  
  .flickity-button:active {
    opacity: 0.6;
  }
  
  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }
  
  .flickity-button-icon {
    fill: currentColor;
  }
  
  /* ---- previous/next buttons ---- */
  
  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  
  .flickity-prev-next-button.previous { left: 10px; }
  .flickity-prev-next-button.next { right: 10px; }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }
  
  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  
  /* ---- page dots ---- */
  
  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: 50px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }
  
  .flickity-rtl .flickity-page-dots { direction: rtl; }
  
  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
  }
  
  .flickity-page-dots .dot.is-selected {
    opacity: 1;
  }