$regular: normal;
$medium: 500;
$bold: bold;
$desktop: 1920px;
$tablet: 768px;
$mobile: 680px;
$siteWidth: 1100px;

// typography
$letterSpacing: 0.06em;

// Colours ---
$backgroundPanelColor: #F6F7FA;
$backgroundPanelColorSecondary: #2C3136;
$textColorPrimary: #2C3136;
$textColorSecondary: white;
$borderAccentColor: #FFD256;
$articleCardBackground: white;

$buttonPrimary: #FFD256;