.page-base {
    padding-top: 50px;

    @media (max-width: $tablet) {
        padding-top: 0;
        background: linear-gradient(180deg, var(--color-gradientA) 500px, var(--color-gradientB) 500px) !important;
    }

    .header .intro {
        @media (max-width: $tablet) {
            width: 100% !important;
            padding: 30px !important;
        }

    }
}