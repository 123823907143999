

h1, h2, h3, h4, h5, h6, label {
    letter-spacing: $letterSpacing;
    line-height: 1.1em;
}

p {
    font-size: 16px;
    line-height: 1.5em;
}

.article-references {
    ol {
        list-style: auto;
        margin-left: 10px;

        li {
            padding-left: 5px;
        }
    }
}

.content-body {

    img {
        max-width: 100%;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 1.2em;
        color: var(--color-text);
        font-weight: 400;
        margin: 0 0 20px 0 !important;
        letter-spacing: -0.009em;
        padding: 0;
        margin: 0 !important;
        margin-bottom: 20px !important;
        padding: 0 !important;
        border: none !important;

        span {
            font-size: 1.2em;
            color: var(--color-text);
            font-weight: 400;
            margin: 0 0 20px 0 !important;
            letter-spacing: -0.009em;
            padding: 0;
            margin: 0 !important;
            margin-bottom: 20px !important;
            padding: 0 !important;
            border: none !important;
        }
    }

    h2 {
        font-size: 1.3em;
    }

    h3 {
        font-size: 1.2em;
    }

    h4 {
        font-size: 1.1em;
    }

    h5 {
        font-size: 1em;
    }

    h6 {
        font-size: 0.9em;
    }

    p,span {
        font-size: 18px;
        line-height: 1.5em;
        margin-bottom: 1.5em;
        color: var(--color-text);

        &:last-child {
            margin-bottom: 0;
        }

        span {
            font-size: 18px;
            line-height: 1.5em;
            margin-bottom: 1.5em;
            color: var(--color-text);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    sup {
        font-size: 0.6em;
        position: relative;
        top: -5px;
    }

    blockquote {
        border-left: 20px solid $borderAccentColor;
        padding-left: 25px;
        margin-bottom: 30px;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: 400;
    }

    ul {
        padding-left: 15px;
        margin: 40px 0;

        li {
            list-style: disc;
            font-size: 18px;
            margin-bottom: .5em;
            line-height: 1.5em; 
        }
    }

    ol {
        padding-left: 30px;
        margin: 15px 0;

        li {
            list-style:decimal;
            font-size: 18px;
            margin-bottom: .5em;
            line-height: 1.5em;
        }
    }

    pre {
        text-wrap: wrap;
    }

    table {
        padding: 20px;
        background: rgba(184, 219, 230, .2);
        margin: 16px 0px;
        display: inline-block;
        font-size: 18px;
        line-height: 1.2em;
        display: block;
        margin: 20px 0;
        margin-bottom: 40px;
        border-bottom: 5px solid #B8DBE6;

        td p {
            padding: 0 115px !important;
        }

        div {
            text-align: left;
            line-height: 1.5em;
            font-size: 18px;
        }
    }

    sup {
        margin-left: 2px;
        margin-right: 2px;
        border-bottom: 1px solid var(--color-text);
        cursor: pointer;
        

        &:hover {
            opacity: .5;
        }
    }

    a {
        color: var(--color-text);
    }

    .footer-link {
        border-bottom: 1px solid var(--color-text);
        padding: 0 2px;
        margin: 0 2px;
        cursor: pointer;
        font-size: 9px;
        vertical-align: top;
        position: relative;
        top: -5px;

        &:hover {
            opacity: .5;
        }
    }
}

body .page-fragment-article .article-content .inner table td {
    h1, h2, h3, p {
        padding: 0 !important;
    }
}
