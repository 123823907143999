.page-subscribe {
    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    p.subscribe-subtitle {
        max-width: 50%;
        text-align: center;

        @media (max-width: $tablet) {
            width: 100%;
            max-width: 100%;
            padding: 30px;
        }
    }

    .thankyou {
        opacity: 0;
        transition: .4s ease;
        height: 0;
        overflow: hidden;

        &.sent {
            opacity: 1;
            transition: .4s ease;
            height: auto;
        }
    }

    form {
        opacity: 1;
        transition: .4s ease;

        &.sent {
            opacity: 0;
            transition: .4s ease;
        }

        label {
            display: block;
            font-weight: 400;
            font-size: 20px;
        }

        input[type="text"], input[type="email"] {
            width: 100%;
            padding: 10px;
            margin: 10px 0 20px 0;
            box-sizing: border-box;
        }

        .unsubscribe-detail {
            margin-top: 50px;
            font-size: 14px;
        }

        .marketing {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;

            span {
                font-size: 18px;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}


.page-subscribe{
    background: linear-gradient(180deg, var(--color-gradientA) 45%, var(--color-gradientB) 45%);

    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .textInput {
        width: 100%;
        margin: 10px 0 20px 0;
        
    }

    label {
        letter-spacing: 0 !important;
    }

   
    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column; 
        padding: 0;

        div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            width: 100%;
            max-width: $siteWidth;
            border-bottom: 1px solid var(--color-border);
            align-items: center;
            padding-bottom: 20px;

            h2 {
                text-transform: uppercase;
                color: #2C3136;
                font-size: 1.8rem;
                line-height: 1em;
                font-weight: 300;
                margin-bottom: 0;
                letter-spacing: -0.01em;
            }

            .search {
                width: 300px;
                max-width: 300px;
                min-width: 300px;
            }
        
        }

        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            
            span {
                &.flag {
                    width: 150px;
                }
                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        p.endorsed {
            font-size: 1rem;
            text-transform: uppercase;
            color: var(--color-text);
            font-weight: 400;
            margin: 0 0 30px 0;
            width: 100%;
            max-width: 650px;
            display: flex;
            align-items: center;

            svg {
                margin-right: 20px;
                width: 50px;
                height: 50px;
            }
        }

        .intro {
            background: var(--color-background);
            padding: 60px 135px;
            margin-top: 60px;
            box-sizing: border-box;
            max-width: $siteWidth;
            width: 100%;

            @media (max-width: $tablet) {
                margin-top: 30px;
            }
        }
    }
}
