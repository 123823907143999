.page-search {
    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    p.subscribe-subtitle {
        max-width: 50%;
        text-align: center;

        @media (max-width: $tablet) {
            width: 100%;
            max-width: 100%;
            padding: 30px;
        }
    }

   .search-result {
    border-bottom: 1px solid #ccc;
    margin-bottom: 25px;
    padding-bottom: 25px;
   }
}


.page-search{
    background: linear-gradient(180deg, var(--color-gradientA) 500px, var(--color-gradientB) 500px);

    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .textInput {
        width: 100%;
        margin: 10px 0 20px 0;
        
    }

    label {
        letter-spacing: 0 !important;
    }

   
    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column; 
        padding: 0;

        

        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            flex-direction: column;
            
            span {
                display: block;
                opacity: 0.3;
            }
        }

        p.endorsed {
            font-size: 1rem;
            text-transform: uppercase;
            color: var(--color-text);
            font-weight: 400;
            margin: 0 0 30px 0;
            width: 100%;
            max-width: 650px;
            display: flex;
            align-items: center;

            svg {
                margin-right: 20px;
                width: 50px;
                height: 50px;
            }
        }

        .intro {
            background: var(--color-background);
            padding: 60px 135px;
            margin-top: 60px;
            box-sizing: border-box;
            max-width: $siteWidth;
            width: 100%;

            @media (max-width: $tablet) {
                margin-top: 30px;
            }
        }
    }
}
