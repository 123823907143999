.socials {
    margin-bottom: 20px; 
    display: block;

    button {
        background: none;
        border: none;
        padding: 0;
        margin-right: 10px;

        &:hover {
            opacity: 0.7;
            cursor: pointer;
        }
    }
}

.socials.share {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    margin-left: 135px;

    @media (max-width: $tablet) {
        margin-left: 0;
    }

    div:first-child {
        border-right: 1px solid var(--color-border);
        padding-right: 10px;
    }

    label {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        padding: 0 40px;
        padding-left: 20px;
        padding-right: 30px;
        letter-spacing: 0.2em;
    }

    path, circle {
        fill: var(--color-fill-icon);
    }

    .stroke {
        fill: var(--color-fill-icon);
        stroke: var(--color-fill-icon);
    }
}

button.primary {
    background: $buttonPrimary;
    display: inline-block;
    border: none;
    padding: 12px 30px;
    font-size: 14px;
    text-transform: uppercase;
    color: #2C3136;
    font-weight: 400;
    letter-spacing: 0.1em;
    border-radius: 5px;
    font-weight: 500 !important;

    &.dark {
        background: $textColorPrimary;
        color: #2C3136;
    }

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
}

.sun-inner {
    fill: var(--color-sun-fill) !important;
    stroke: var(--color-sun-stroke) !important;
}

.moon-inner {
    fill: var(--color-moon-fill) !important;
}

.pagination {
    display: flex;
    justify-content: center;
    padding: 10px;

    @media (max-width: $tablet) {
        .page-link {
            display: none;
        }
    }

    button {
        margin: 3px;
        background: none; 
        border: var(--color-border) 1px solid;
        width: 35px;
        height: 35px;
        border-radius: 4px;
        cursor: pointer;

        &.btn-prev, &.btn-next {
            border: none;
            background: none;
            position: relative;
            left: -30px;
            top: -6px;

            svg {
                transition: .4s ease;
            }

            &:hover {
                background: none;
                border: none;

                svg {
                    transform: scale(1.1);
                    transition: .4s ease;
                }
            }
        }

        &.btn-next {
            right: -10px;
            left: unset;

            svg {
                transform: rotate(180deg);
            }

            &:hover {
                svg {
                    transform: scale(1.1) rotate(180deg);
                    transition: .4s ease;
                }
            }
        }

        &:hover {
            background: #FFD256;
            border: #FFD256 1px solid;
        }

        &.active {
            background: #FFD256;
            border: #FFD256 1px solid;
        }
    }
}

.slider-controls {
    display: flex;

    button {
        background: none;
        padding: 0;
        border: none;
        cursor: pointer;

        &:first-child {
            margin-right: -5px;
        }

        &:last-child {
            svg {
                transform: rotate(180deg) scale(0.8);
            }
        }

        &:hover {
            svg {
                transform: scale(1);
            }

            &:last-child {
                svg {
                    transform: rotate(180deg) scale(.9);
                }
            }
        }

        svg {
            transform: scale(0.8);
            transition: .4s ease;
        }
    }
}

.btn-next-news, .btn-next {
    margin-left: 10px;
}