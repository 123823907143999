.page-resources {
    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .index-inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .resource-card {
        width: 33%;


        background-color: var(--color-background-resource-card) !important;
        img {
            max-width: 100%;
        }
    }
 
}



.page-resources{
    background: linear-gradient(180deg, var(--color-gradientA) 300px, var(--color-gradientB) 300px);

    @media (max-width: $tablet) {
        background: linear-gradient(180deg, var(--color-gradientA) 15%, var(--color-gradientB) 15%);
    }

    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .resources-index {
        padding-bottom: 70px;

        @media (max-width: $tablet) {
            padding: 20px;
        }

        article {
            width: 30.666%;
            background: $backgroundPanelColor;
            float: left;
            margin-right: 4%;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            // height: 470px;
            // max-height: 470px;

            @media (max-width: 895px) {
                width: 48%;
                margin: 0 4% 40px 0;
               
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }

            @media (max-width: $tablet) {
                width: 100% !important;
                margin: 0 0 40px 0;
            }

            

            &:nth-child(3n) {
                margin-right: 0;
            }

            .inner {
                padding: 25px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                padding-top: 0;
                margin-top: 20px;
                margin-bottom: 0;
                height: 100%;

                label {
                    font-weight: 400;
                    font-size: 18px;
                    text-transform: uppercase;
                    margin: 10px 0;
                    display: block;
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    flex-direction: column;
                    line-height: 1.3em;
                }

                h2 {
                    text-transform: unset;
                    color: var(--color-text);
                    font-size: 24px;
                    line-height: 1.2em;
                    font-weight: 300;
                    letter-spacing: -0.01em;
                    border-bottom: 1px solid var(--color-border);
                    padding-bottom: 20px;

                    a {
                        text-decoration: none;
                        font-size: 20px;
                        line-height: 20px;
                        color: var(--color-text);
                    }
                }

                p {
                    margin: 20px 0;
                }

                >div {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .filter-search {
        .header {
            padding-top: 0;
            margin-bottom: 0;

            @media (max-width: $tablet) {
                padding: 30px;
                background: var(--color-background);
            }

            div:first-child {
                @media (max-width: $tablet) {
                    flex-direction: column;

                    h2 {
                        margin-bottom: 10px;

                        @media (max-width: $tablet) {
                            margin-bottom: 30px
                        }
                    }
                }
            }

            .filter {
                width: 25%;
                border-bottom: none;
                padding-right: 40px;

                @media (max-width: $tablet) {
                    width: 100%;
                    padding: 0;
                    margin-top: 10px;
                }

                .custom-select {
                    border-bottom: none;
                    margin: 0;
                    padding-bottom: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
                
            }

            .filter-selectors {
                display: flex;
                width: 100%;
                max-width: $siteWidth;
                justify-content: space-between;

                @media (max-width: $tablet) {
                    width: 100%;
                    display: block;
                    margin-top: 10px;
                }
            }
            
            
        }
    }

    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column; 
        padding: 0;
        margin-bottom: 40px;

        div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            width: 100%;
            max-width: $siteWidth;
            border-bottom: 1px solid var(--color-border);
            align-items: center;
            padding-bottom: 20px;

            @media (max-width: $tablet) {
                margin-bottom: 0;
              }

            h2 {
                text-transform: uppercase;
                color: var(--color-text);
                font-size: 1.8rem;
                line-height: 1em;
                font-weight: 300;
                margin-bottom: 0;
                letter-spacing: -0.01em;
            }

            .search {
                width: 300px;
                max-width: 300px;
                min-width: 300px;
            }
        
        }

        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            
            span {
                &.flag {
                    width: 150px;
                }
                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        p.endorsed {
            font-size: 1rem;
            text-transform: uppercase;
            color: var(--color-text);
            font-weight: 400;
            margin: 0 0 30px 0;
            width: 100%;
            max-width: 650px;
            display: flex;
            align-items: center;

            svg {
                margin-right: 20px;
                width: 50px;
                height: 50px;
            }
        }

        .intro {
            background: var(--color-background);
            padding: 60px 135px;
            margin-top: 60px;
            box-sizing: border-box;
            width: 100%;

            @media (max-width: $tablet) {
                padding: 40px 80px;
                width: 90%;
            }
        }
    }

   
    .state-positions-index {
        padding: 40px;
        padding-top: 0;
        display: flex;
        justify-content: space-between;
        background: white;

        

        .inner {
            width: 100%;
            border-top: 1px solid var(--color-border);
            padding-top: 40px;
            display: flex;
            flex-wrap: wrap;
        }
        
        .country-card {
            width: 32%;
            margin-right: 2%;
            background: #F6F7FA;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            div {
                &:first-child {
                    width: 30%;
                }

                &:last-child {
                    width: 70%;
                    padding: 20px;
                    box-sizing: border-box;

                    a {
                        text-transform: uppercase;
                        color: var(--color-text);
                        font-size: 1rem;
                        line-height: 1em;
                        font-weight: 300;
                        margin-bottom: 20px;
                        letter-spacing: 0.03em;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    
}
