

#page-home {

    .carousel {

        height: 600px;

        @media (max-width: $tablet) {
            height: 800px;
        }

        article {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        .content-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            position: relative;
            
            @media (max-width: $tablet) {
                width:100%;
            }
        }

        .inner-banner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            height: 100%;

            p {
                margin-bottom: 20px;
            }
        }

        .slider-controls {
            margin-bottom: 40px;

            @media (max-width: $tablet) {
                margin-top: 20px !important;
            }

        }

        .image-background {
            margin: 40px 0;
            
            @media (max-width: $tablet) {
                display: none;
            }
        }
    }

    .controls-wrapper {
        padding:0;
        width: 100%;

        @media (max-width: $tablet) {
            display: flex;
            justify-content: space-between;

            .slider-controls {
                margin: 0;
                padding: 0;
                width: auto;
            }
        }

    }

    .slider-controls {
        padding-left: 0;
        margin-top: 0;
    }

    .news-banner {
        .inner {
            @media (max-width: $tablet) {
                padding: 30px;
                padding-bottom: 0;
            }
        }
        .slider-controls {
            margin-top: 0;
        }
    }

    .news-carousel {
        .card-image {
            padding-top: 100%;
        }
    }

    .article-inner {
        background: var(--color-background-home-card);
    }

    .article-card {
        .article-inner {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}