
.page-team{
    background: linear-gradient(180deg, var(--color-gradientA) 30%, var(--color-gradientB) 30%);

   

    .inner {
        max-width: $siteWidth;
        margin: auto;
    }
   
    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column; 
        padding: 0;

       

        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            
            span {
                &.flag {
                    width: 150px;
                }
                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        .intro {
            background: var(--color-background);
            padding: 60px 135px;
            margin-top: 60px;
            box-sizing: border-box;
            max-width: $siteWidth;
            width: 100%;
        }
    }

    .team-box {
        background: #FFD256;
        width: 100%;
        max-width: $siteWidth;
        padding: 60px 60px;
        margin-bottom: 40px;
        box-sizing: border-box;
        margin-top: 50px;

        @media (max-width: $tablet) {
            padding: 60px 30px;
            margin-bottom: 0;
        }

        h2.heading {
            text-transform: uppercase;
            color: #2C3136;
            font-size: 28px;
            line-height: 1.4rem;
            font-weight: 300;
            margin-bottom: 20px;
            letter-spacing: -0.01em;
            border-bottom: 1px solid #2C3136;
            padding-bottom: 20px;
            margin-bottom: 40px;
 
        }

        .team-member {
            display: flex;

            .member {
                cursor: pointer;
                transition: 0.5s ease;
            }

            @media (max-width: $tablet) {
                flex-direction: column;
            }
        }

        .team-member-images {
            width: 50%;
            padding-right: 70px;

            @media (max-width: $tablet) {
                width: 100%;
                padding-right: 0;
                margin-bottom: 40px;
            }
        }

        .team-member-info {
            width: 50%;

            @media (max-width: $tablet) {
                width: 100%;

            }

            
        }

        .details {
            color: #2C3136 !important;

            p {
                color: #2C3136 !important;
                span {
                    color: #2C3136 !important;
                }
            }
        }

        .mainImageContainer {
            padding-top: 75%;
            background-size: 100%;
            background-position: center;
        }

        .member-list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 20px;

            li {
                width: 25%;
                margin-right: 10px;
                background: black;

                &:last-child {
                    margin-right: 0;
                }

                div {
                    width: 100%;
                    padding-top: 65%;
                    margin-right: 0%;
                    background-size: cover;
                    background-position: center;
                    background-color: black;
                    opacity: .5;
                }

                &.active{
                    div {
                        opacity: 1;
                    }
                }
            }

            li:hover {
                div {
                    opacity: 1;
                }
            }
        }
    
    

        .content-body {
            h1, h2, h3, p {
                color: #2C3136;
            }

            h1, h2 {
                text-transform: uppercase;
                font-size: 28px;
                font-weight: 100;
            }

            a {
                color: #2C3136;
            }
        }
    }

    .editorial-board {
        margin-top: 40px;

        @media (max-width: $tablet) {
            margin-top: 0;
            padding: 30px;
            padding-bottom: 0;
        }

        h2.heading {
            text-transform: uppercase;
            color: #2C3136;
            font-size: 28px;
            line-height: 1.4rem;
            font-weight: 300;
            margin-bottom: 20px;
            letter-spacing: -0.01em;
            border-bottom: 1px solid #2C3136;
            padding-bottom: 20px;
            margin-bottom: 40px;

            @media (max-width: $tablet) {
                margin-top: 30px;
            }
 
        }

        .left, .right {
            width: 45%;

            @media (max-width: $tablet) {
                width: 100%;
                margin-bottom: 40px;
            }
        }

        .right {
            padding-left: 50px;

            @media (max-width: $tablet) {
                padding-left: 0;
                margin-bottom: 0;
            }

            h3 {
                text-transform: uppercase;
            }

            .editorial-board-list {
                padding: 0;
                margin: 0;

                li {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 20px;
                    border-bottom: 1px solid var(--color-border);
                    padding-bottom: 20px;

                    h3 {
                        width: 40%;
                        margin-top: 5px;
                        font-weight: 300;
                        font-size: 20px;
                    }

                    div {
                        width: 60%;
                    }
                }
            }
        }

        .panels {
            display: flex;
            justify-content: space-between;

            @media (max-width: $tablet) {
                flex-direction: column;
            }
        }

    }

    .partners {
        margin-top: 40px;
        background-color: #FFD256;
        width: 100%;
        padding: 60px 0;
        box-sizing: border-box;

        @media (max-width: $tablet) {
            padding: 60px 30px;
        }

        .inner {
            width: 100%;
            max-width: $siteWidth;
        }

        h2.heading {
            text-transform: uppercase;
            color: #2C3136;
            font-size: 28px;
            line-height: 1.4rem;
            font-weight: 300;
            margin-bottom: 20px;
            letter-spacing: -0.01em;
            border-bottom: 1px solid #2C3136;
            padding-bottom: 20px;
            margin-bottom: 40px;
 
        }

        .partners-content {
            display: flex;
            justify-content: space-between;
            
            .content-body {
                color: #2C3136;

                p {
                    color: #2C3136;
                }
            }
            

            @media (max-width: $tablet) {
                flex-direction: column;
            }

            .left, .right {
                width: 45%;

                @media (max-width: $tablet) {
                    width: 100%;
                }
            }

            .right {
                display: flex;
                justify-content: center;
                align-items: flex-start;

                @media (max-width: $tablet) {
                    margin-top: 30px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    max-height: 120px;

                    @media (max-width: $tablet) {
                        margin-right: 0 !important;
                        margin-bottom: 40px;
                    }

                    &:first-child {
                        margin-right: 80px;
                    }
                }
            }
            
        }
    }
}
