.mailinglist {
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $backgroundPanelColorSecondary;
    position: relative;

    @media (max-width: $tablet) {
        height: auto;
        padding: 0;
        flex-direction: column-reverse;
     }

     .inner {
        @media (max-width: $tablet) {
            padding: 60px 30px;
        }
     }
    
    .background-image {
        background-image: url('./static/images/mailinglist-background.jpg');
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 50%;

        @media (max-width: $tablet) {
            width: 100%;
            position: static;
            padding-top: 100%;
         }
    }

    h2 {
        font-size: 2.6rem;
        color: white;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        line-height: 1em;
        letter-spacing: -0.01em;
    }

    p {
        font-size: 1rem;
        color: $textColorSecondary;
        line-height: 1.4em;
        margin: 40px 0;
    }

    button {
        max-width: 170px;
    }

    div {
        display: flex;
        max-width: $siteWidth;
        flex-direction: row;
        
        > div {
            width: 40%;
            display: block;

            @media (max-width: $tablet) {
                width: 100%;
             }
        }
    }
}