.stats {
    justify-content: center;
    display: flex;
    flex-direction: column;
    background: var(--color-background);

    .inner {
        max-width: $siteWidth;
        margin: auto;
        padding: 70px 0;

        @media (max-width: $tablet) {
           padding-top: 30px;
           padding-bottom: 0;
        }
    }

   .top {
        display: flex;
        margin-bottom: 20px;

        @media (max-width: $tablet) {
            flex-direction: column-reverse;
        }

        .total-civ {
            @media (max-width: $tablet) {
                padding: 0 30px;
            }
        }

        div {
            width: 50%;

            @media (max-width: $tablet) {
                width: 100%;
                padding: 30px;
            }
        }

        .btn-viewmore-data {
            @media (max-width: $tablet) {
                margin: 30px 0;
            }
        }

        label {
            color: $buttonPrimary;
            width: 90%;
        }

        .content-body {
            box-sizing: border-box;

            @media (max-width: $tablet) {
                padding: 0;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px 40px;
            padding-top: 0;
            align-items: flex-end;
            padding-right: 0;

            @media (max-width: $tablet) {
                padding: 30px;
            }

            >div {
                @media (max-width: $tablet) {
                    padding: 0;
                }
            }

            ul {
                margin: 0;
            }

            .data-selector {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                box-sizing: border-box;

                @media (max-width: $tablet) {
                    padding: 0;
                }

                .custom-select {
                   width: 60%;
                   max-width: 60%;
                   min-width: 60%;

                   @media (max-width: $tablet) {
                    width: 100%;
                    max-width: 100%;
                    min-width: 100%;
                    padding: 0;
                  }
                }

                div {
                    width:70%;
                }
            }

            div {
                width: 80%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                @media (max-width: $tablet) {
                    width: 100%;
                }

                div {
                    height: initial;
                    width: 100%;

                    p {
                        margin-bottom: 20px;
                    }

                    button {
                        max-width: 150px;
                      
                    }
                }
            }
        }
   }

   h2 {
        text-transform: uppercase;
        color: var(--color-text);
        font-size: 2.5rem;
        line-height: 1em;
        font-weight: 300;
        margin-bottom: 40px;
        letter-spacing: -0.01em;
    }

    h3 {
        font-family: astoria-sans-condensed, sans-serif;
        font-weight: 500;
        font-style: normal;
        text-transform: uppercase;
        font-size: 1.3rem;
        margin-bottom: 20px;
        letter-spacing: -0.01em;
    }

    label {
        font-family: futura-pt, sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 6rem;
        letter-spacing: -0.1em;
        border-top: 1px solid var(--color-border);
        border-bottom: 1px solid var(--color-border);
        padding: 0px;
        line-height: 0.9em;
        display: block;
        margin: 20px 0;

        @media (max-width: $tablet) {
            border-top: none;
        }

        span {
            &:first-child {
                color: $buttonPrimary;
            }

            &:last-child {
                letter-spacing: -0.01em;
                font-size: 1.5rem;
                font-family: astoria-sans-condensed, sans-serif;
                font-weight: 300;
                text-transform: uppercase;
                display: flex;
                align-items: flex-end;
                flex-direction: row;
                line-height: 55px;
                margin-left: 10px;

                @media (max-width: $tablet) {
                    line-height: 38px;
                 }
            }
        }
    }

    .bottom {

        @media (max-width: $tablet) {
            padding: 30px;
            padding-top: 0;
         }

        >div {
            display: flex;
            justify-content: space-between;

            @media (max-width: $tablet) {
                flex-direction: column;
             }

            label {
                display: flex;
                width: 30%;
                margin-top: 0;

                @media (max-width: $tablet) {
                    width: 100%;
                 }

                span {
                    &:last-child {
                        display: flex;
                    }
                }
            }
        }
    }
}