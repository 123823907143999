.page-report {
    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media (max-width: $mobile) {
            padding-left: 30px !important;
            padding-right: 30px !important;
        }
    }

    .content {
        padding-right: 300px;

        @media (max-width: $mobile) {
            padding-right: 0;
        }
    }

    .reportImage {
        float: right;
        max-width: 300px;
        position: relative;
        top: -25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: $mobile) {
            float: none;
            max-width: 100%;
            
            button {
                width: 100%;
            }

            img {
                max-width: 100% !important;
            }
        }

        button {
            width: 240px;
        }

        img {
            max-width: 240px;
            margin-bottom: 20px;
        }
    }
}


.page-report {
    background: linear-gradient(180deg, var(--color-gradientA) 45%, var(--color-gradientB) 45%);

    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .textInput {
        width: 100%;
        margin: 10px 0 20px 0;
        
    }

    label {
        letter-spacing: 0 !important;
    }

   
    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column; 
        padding: 0;

        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            
            span {
                &.flag {
                    width: 150px;
                }
                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        p.endorsed {
            font-size: 1rem;
            text-transform: uppercase;
            color: var(--color-text);
            font-weight: 400;
            margin: 0 0 30px 0;
            width: 100%;
            max-width: 650px;
            display: flex;
            align-items: center;

            svg {
                margin-right: 20px;
                width: 50px;
                height: 50px;
            }
        }

        .intro {
            background: var(--color-background);
            padding: 60px 135px;
            margin-top: 60px;
            box-sizing: border-box;
            max-width: $siteWidth;
            width: 100%;

            @media (max-width: $tablet) {
                margin-top: 30px;
            }
        }
    }
}
