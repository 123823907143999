.page-fragments {
    background: linear-gradient(180deg, var(--color-gradientA) 350px, var(--color-gradientB) 350px);

    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column; 
        padding: 0;

        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 400;
            margin-bottom: 10px;
            letter-spacing: .2em;
        }

        .intro {
            background: var(--color-background);
            padding: 30px 135px;
            margin-top: 60px;
            box-sizing: border-box;
        }
    }

}

.footerImage {
    padding-top: 64%;
    background-size: contain;
    background-repeat: no-repeat;
}

.fragment-issues-scroller {
    background-color: $backgroundPanelColorSecondary;

    @media (max-width: $tablet) {
        padding: 30px;
    }
    
    .issues-header {
        max-width: $siteWidth;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 60px 0 0 0;
        border-bottom: 1px solid #525252;
        align-items: flex-start;

        @media (max-width: $tablet) {
            flex-direction: column;
        }

        .custom-select {
            width: 300px;
            max-width: 300px;
            min-width: 300px;

            @media (max-width: $tablet) {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
            }
        }

        h2 {
            text-transform: uppercase;
            color: white;
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 40px;
            letter-spacing: -0.01em;
        }
    }

    .inner {
        display: flex;
        margin: auto;
    }
}

.fragment-article-index {
    padding: 40px;
    background: var(--color-background-panel);

    @media (max-width: $tablet) {
        padding: 30px;
    }

    .articles-header {
        display: flex;
        justify-content: space-between;
        margin: 0 0 20px 0;
        padding-bottom: 40px;
        border-bottom: 1px solid var(--color-border) !important;
        margin-bottom: 40px;
        margin-top: 40px;

        @media (max-width: $tablet) {
            flex-direction: column;
        }

        .search-box {
            @media (max-width: $tablet) {
                margin-top: 30px;
            }
        }

        input[type="text"] {
            padding: 10px;
            min-width: 200px;
            border-radius: 8px;
            border: 1px solid var(--color-border);
        }
    }

    .index {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .card {
        width: 32%;
        margin-right: 2%;

        @media (max-width: $tablet) {
            width: 100%;
            margin-right: 0;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        .article-card {
            margin:0 0 20px 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 96%;

            .article-inner {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }
        }

        .article-inner {
            background: var(--color-background-fragment-article-card);
        }
    }

    h2 {
        text-transform: uppercase;
        color: var(--color-text);
        font-size: 2.5rem;
        line-height: 1.4rem;
        font-weight: 300;
        letter-spacing: -0.01em;
    }
}

article.article-card {
    background: var(--color-cardBackground);
    margin-right: 20px;

    img {
        max-width: 100%;
    }

    .article-inner {
        padding: 20px;
        background: var(--color-background-fragment-card);
    }

    label {
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 400;
        margin: 0 0 20px 0;
        display: block;
        color: var(--color-text);
        

        span {
            padding-right: 10px;
            border-right: 1px solid var(--color-border);
            margin-right: 10px;
            display: inline-block;
            line-height: 10px;

            &:last-child {
                border-right: none;
            }
        }
    }

    h2 {
        text-transform: uppercase;
        color: var(--color-text);
        font-size: 28px;
        line-height: 1em;
        font-weight: 300;
        margin-bottom: 20px;
        letter-spacing: -0.01em;

        a {
            color: var(--color-text);
            text-transform: none; 
            font-size: 20px;
            text-decoration: none;
        }
        
    }
}

.contributer-banner {
    padding: 60px 0;
    background: $buttonPrimary;

    @media (max-width: $tablet) {
        padding: 30px;
    }

    h2 {
        text-transform: uppercase;
        color: #2C3136;
        font-size: 28px;
        line-height: 1.4rem;
        font-weight: 300;
        margin-bottom: 20px;
        letter-spacing: -0.01em;
        border-bottom: 1px solid #2C3136;
        padding-bottom: 20px;
        margin-bottom: 30px;
    }

    .details {
        display: flex;

        @media (max-width: $tablet) {
            flex-direction: column;
        }

        p {
            color: #2C3136;
            margin-bottom: 20px;
        }

        a {
            color: #2C3136;
        }

        button {
            color: #fff;
        }

        div {
          
            &:first-child {
                width: 60%;

                @media (max-width: $tablet) {
                    width: 100%;
                }
            }

            &:last-child {
                text-align: right;
                width: 40%;

                @media (max-width: $tablet) {
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }
}

.page-fragment-issue {
    background: var(--color-background-fragment-issues) !important;
    padding-top: 0 !important;

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--color-border);
        padding-bottom: 20px;
        max-width: $siteWidth;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;

        h2 {
            text-transform: uppercase;
            font-size: 1.8rem;
            letter-spacing: -0.009em;
        }
    }

    
    .inner {
        max-width: $siteWidth;
        width: 100%;
        margin: auto;
        display: flex;

        div {
            width: 50%;
        }
    }

    .article-lead {

        &:nth-child(odd) {
            background-color: var(--color-background-issue-odd);

            .inner {
                flex-direction: row-reverse;
                

                @media (max-width: $tablet) {
                    flex-direction: column-reverse;
                    padding-top: 0 !important;
                }
            }

            .left {
                justify-content: center !important;
                align-items: center !important;

                @media (max-width: $tablet) {
                    justify-content: flex-start !important;
                    align-items: flex-start !important;
                }

                div {
                    width: 100%;
                    padding-left: 0;
                    width: 70% !important;
                    padding-right: 0 !important;
                    box-sizing: border-box;

                    @media (max-width: $tablet) {
                        padding-left: 0px !important;
                        padding: 0 !important;
                        width: 100% !important;
                    }
                }
            }
        }


        .inner {
            max-width: $siteWidth;
            display: flex;
            flex-direction: row;
            margin: auto;
            padding-bottom: 30px;
            padding-top:30px;

            @media (max-width: $tablet) {
                flex-direction: column-reverse;
            }

            
            .left {
                width: 60%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                @media (max-width: $tablet) {
                    width: 100%;
                    padding: 20px !important;
                }

                div {
                    width: 100%;
                    padding-right: 180px;
                    box-sizing: border-box;

                    @media (max-width: $tablet) {
                        padding-right: 0;
                    }
                }

                h2 {
                    text-transform: uppercase;
                    font-size: 2em;
                    letter-spacing: -0.009em;
                    margin-bottom: 10px;
                }

                h3 {
                    font-size: 1.2rem;
                    letter-spacing: -0.003em;
                    margin-bottom: 10px;
                    font-weight: 400;
                }

                label {
                    font-size: 1rem;
                    font-weight: 400;
                    margin: 0 0 0 0;
                    display: block;
                    color: var(--color-text);
                    font-size: 1rem;
                    letter-spacing: -0.003em;
                    font-weight: 100;
                    line-height: 1.5em;

                
            
                    span {
                        padding-right: 10px;
                        border-right: 1px solid var(--color-border);
                        margin-right: 10px;

                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }

            .right {
                width: 50%;
                padding-top: 45%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                margin: 0 0 30px 0;

                @media (max-width: $tablet) {
                    width: 100%;
                    padding-top: 60%;
                }
            }
        }
    }

    

    .fragment-issue-header {
        height: 65vh !important;
        flex-direction: column;
        display: flex;
        overflow: hidden;
        background: var(--color-background-panel);

        .inner {
            padding: 100px 0 !important;

            @media (max-width: $tablet) {
                padding: 50px 30px !important;
            }
        }

        h1 {
            text-transform: uppercase;
            color: $textColorPrimary;
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 400 !important;
            margin-bottom: 10px !important;
            letter-spacing: 0.06em !important;
        }

        h3 {
            font-family: astoria-sans-condensed, sans-serif;
            font-weight: 400;
            font-style: normal;
            text-transform: uppercase;
            font-size: 1.8rem;
            margin-bottom: 20px;
            letter-spacing: -0.01em;

            span {
                border-right: 2px solid #ccc;
                line-height: 25px;
                display: inline-block;
                padding: 0 10px 0 0;    
                margin-right: 10px;  
                
                &:last-child {
                    border-right: none;
                    position: relative;
                    left: -5px;
                }
            }
        }

        button {
            margin: 10px 0;
        }

        .custom-select {
            margin-top: 20px;
        }

        select {
            display: block;
        }

        .inner {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-size: 65%;
            background-repeat: no-repeat;
            background-position: 113% 0%;

            @media (max-width: $tablet) {
                background: none !important;
                flex-direction: column;
            }



            div {
                width: 50%;

                @media (max-width: $tablet) {
                    width: 100%;
                }
    
                &:first-child {
                    box-sizing: border-box;
                }
    
                &:last-child {
                    display: flex;
                    flex-direction: column;
                    align-items: self-end;
                    justify-content: flex-end;
                }

                img {
                    max-width: 100%;
                    position: relative;
                    right: -40px;
                  }
            }

            .left {
                display: flex;
                max-width: 400px;

                @media (max-width: $tablet) {
                   max-width: 100%;
                   width: 100%;
                }

                div {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: 100%;
                }
            }
        }
    }

    .issue-index {

        max-width: 100% !important;
        background: var(--color-background-fragment-issues);

        @media (max-width: $tablet) {
            padding: 30px;
        }

        .heading {
            margin-bottom: 0;

            @media (max-width: $tablet) {
                margin-top: 30px;
            }
        }

        h2 {
            margin: 0 0 20px 0;
        }

        article {
            display: flex;

            div {
                width: 50%;

                img { 
                    max-width: 100%;
                }
            }
        }
    }
}

.page-fragment-article {

    background: linear-gradient(180deg, var(--color-gradientA) 900px, var(--color-gradientB) 900px);

    .inner {
        max-width: $siteWidth;
        margin: auto;
        padding: 60px 0;
    }

    

    .article-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: $siteWidth;
        margin: auto;

        .inner {
            padding: 60px 350px 60px 135px;

            @media (max-width: $tablet) {
                padding: 30px;
            }
        }

        p {
            font-size: 1.3rem;
            text-transform: uppercase;
            color: var(--color-text);
            font-weight: 400;
            margin: 20px 0 20px 0;
            line-height: 1.2em;
        }

        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 10px;
        }

        label {
            font-size: 1.2rem;
            text-transform: uppercase;
            color: var(--color-text);
            font-weight: 100;
            margin: 0;
            display: block;
            

            &.issue {
                font-size: .8rem;
                font-weight: 400;
                margin-top: 40px;

                span {
                    padding-right: 10px;
                    margin-right: 10px;
                    border-right: 1px solid var(--color-border);
                    display: inline-block;
                }
            }
        }
    }

    .header-image {
        padding-top: 50%;
        position: relative;

        @media (max-width: $tablet) {
            min-height: 70vh;
        }
        
        .inner {
            max-width: 100%;
            margin: auto;
            padding: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 370px;
            right: 0;
            background-size: cover;
            background-position: bottom right;
            background-repeat: no-repeat;
            padding-top: 50%;

            @media (min-width:$tablet) and (max-width:$siteWidth) {
                left:0;
            }

            @media (max-width: $tablet) {
                left: 0;
            }
            
        }
    }

    .caption {
        display: block;
        height: 100%;
        padding: 10px 0 20px 0;
        font-size: 13px;
        letter-spacing: 0.03em;
        border-bottom: 5px solid $borderAccentColor;

        @media (max-width: $tablet) {
            padding: 10px 30px 20px 30px;
        }
    }

    .article-content {
        padding: 40px;
        padding-top: 0;
        max-width: $siteWidth;
        margin: auto;
        padding-bottom: 0;

        @media (max-width: $tablet) {
            padding: 30px;
            padding-top: 50px;
        }

        

        .inner {
            padding-bottom: 0;

            h1,h2,h3, h4, h5, ul, p {
                padding: 0 135px !important;

                @media (max-width: $tablet) {
                    padding: 0 !important;
                }
            }

            blockquote {
                margin: 35px 135px !important;

                @media (max-width: $tablet) {
                    margin: 35px 0 !important;
                }

                p {
                    padding: 0 !important;
                }
            }

            ul {
                padding-right: 150px;
                position: relative;
                left: 40px;
            }

            td {
                ul {
                  padding-left: 0 !important;
                }
            }

            
            @media (max-width: $tablet) {
                padding: 0;
            }
        }
    }

    .article-references {
        font-size: 16px;
        line-height: 1.5em;
        border-top: 1px solid var(--color-border);
        border-bottom: 1px solid var(--color-border);
        max-width: $siteWidth;
        margin: auto;
        margin-top: 60px;

        a {
            color: var(--color-text);
            font-size: 16px;
            font-family: astoria-sans-condensed, sans-serif !important;
        }

        @media (max-width: $tablet) {
            margin-top: 0;
        }

        .inner {
            padding: 40px 0;

            @media (max-width: $tablet) {
                padding: 30px;
            }
        }
    }

    .article-recommended {

        .inner {
            padding: 40px 0;

            @media (max-width: $tablet) {
                margin-left: 30px;
                margin-right: 30px;
            }
        }

        .inner >h2 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 40px;
            letter-spacing: -0.01em;
        }

        article.article-card {
            background: $backgroundPanelColor;
        }
    }

    .recommends {
        display: block;
          
        
        .card {
            margin-right: 20px;
            width: 31%;
            margin-bottom: 30px;
            float: left;

            @media (max-width: $tablet) {
                width: 100% !important;
                margin-right: 0% !important;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }

            @media (max-width: $tablet) {
                &:nth-child(2n) {
                    margin-right: 0 !important;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            article {
                margin-right: 0;
            }
        }
    }
}

.article-recommended {
    .card {
        width: 32% !important;
        margin-right: 2% !important;

        h2 {
            height: 100px;
        }

        &:nth-child(3n) {
            margin-right: 0 !important;
        }

        @media (max-width: $tablet) {
            &:nth-child(2n) {
                margin-right: 0 !important;
            }
        }

        h2 {
            line-height: 1em;
        }
    }
}

.news-banner {
    background: $backgroundPanelColor;
    padding: 70px 0;
    
    .inner {
        max-width: $siteWidth;
        margin: auto;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 2.5rem;
            color: var(--color-text);
            text-transform: uppercase;
            letter-spacing: 0.03em;
            line-height: 1em;
            margin-bottom: 40px;
            letter-spacing: -0.01em;
        }
    }

    
}