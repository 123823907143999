.custom-select {
  min-width: 100%;
  position: relative;
}

select {
  appearance: none;
  /*  safari  */
  -webkit-appearance: none;
  /*  other styles for aesthetics */
  width: 100%;
  font-size: 18px;
  padding: 0.675em 40px 0.675em 1em;
  background-color: var(--color-background-input);
  border: 1px solid var(--color-border-input);
  border-radius: 0.25rem;

  cursor: pointer;
  text-transform: uppercase;
    color: var(--color-text);
    font-size: 16px;
    letter-spacing: 1px;
}

::placeholder {
  color: #b2b2b2;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #b2b2b2;
}

.search-box {
  position: relative;

  @media (max-width: $tablet) {
    width: 100%;
  }

  svg {
    position: absolute;
    left: 10px;
    top:11px;
    width: 20px;
    height: 20px;
    stroke: var(--color-fill-icon);
    
  }

  input {
    padding-left: 35px !important;

    @media (max-width: $tablet) {
      width: 100%;
    }
  }
}

input.textInput {
    appearance: none;
    /*  safari  */
    -webkit-appearance: none;
    /*  other styles for aesthetics */
    font-size: 18px;
    padding: 0.675em 6em 0.675em 1em;
    background-color: var(--color-background-input);
    border: 1px solid var(--color-border-input);
    border-radius: 0.25rem;
    text-transform: uppercase;
    color: var(--color-text);
    font-size: 16px;
    letter-spacing: 1px;

    
  }



.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid var(--color-border);
  top: 38%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid var(--color-border);
  top: 57%;
}
