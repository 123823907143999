.page-data {
    background: linear-gradient(180deg, var(--color-gradientA) 8%, var(--color-gradientB) 8%);

    .inner {
        max-width: $siteWidth;
        margin: auto;
    }

    .data-disclaimer {
        max-width: $siteWidth;
        margin: auto;
        margin-top: 30px;
    }

    .civilian-impact {
        background: linear-gradient(180deg, var(--color-gradientA) 500px, var(--color-gradientB)  500px) !important;
    }

    .map-controls {
        button {
            padding: 0;
            background: none;
            border: none;
            margin: 0;
            cursor: pointer;

            svg {
                transition: 0.3s ease;
            }

            &:hover {
                svg {
                    transform: scale(1.2);
                    transition: 0.3s ease;
                }
            }
        }
    }

    .data-whitepanel {
        background: linear-gradient(180deg, var(--color-gradientA) 80vh, var(--color-gradientB) 80vh);
        padding-top: 70px;

        @media (max-width: $tablet) {
            padding-top: 30px;
            background: var(--color-background) !important;
        }

        .header {
            padding-top: 0;

            @media (max-width: $tablet) {
                width: 100% !important;
            }

            .intro {
                margin-top: 30px;

                @media (max-width: $tablet) {
                    width: 100% !important;
                    margin-top:0;
                    padding-top: 0;
                }
            }
        }

        h2.heading {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 28px;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 20px;
            letter-spacing: -0.01em;
            border-bottom: 1px solid var(--color-border);
            padding-bottom: 20px;
            margin-bottom: 30px;

            @media (max-width: $tablet) {
                padding: 30px;
            }
        }

        .civilian-percentage-stat {
            display: flex;
            border-bottom: 1px solid var(--color-border);
            padding-bottom: 30px;
            margin-bottom: 40px;

            @media (max-width: $tablet) {
                padding: 30px;
                margin-bottom: 0;
                flex-direction: column;
                align-items: center;
            }

            div {
                &:first-child {
                    width: 30%;

                    @media (max-width: $tablet) {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }

                &:last-child {
                    display: flex;
                    align-items: center;
                    width: 70%;

                    @media (max-width: $tablet) {
                        justify-content: center;
                        width: 100%;
                        text-align: center;
                    }
                }
            }

            label {
                text-transform: uppercase;
                font-family: futura-pt, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 7rem;
                line-height: 0.7em;
                letter-spacing: -15px;
                color: #FFD256;
            }

            em {
                text-transform: uppercase;
                font-size: 1rem;
                letter-spacing: -0.009em;
                width: 70%;
                line-height: 1.3em;

                
            }
        }
    }

    .anual-incidents {
        background: #FFD256;
        max-width: $siteWidth;
        margin: auto;
        padding: 60px 135px;
        box-sizing: border-box;
        margin-bottom: 60px;

        h2, p , span {
            color: #2C3136;
        }

        @media (max-width: $tablet) {
            padding: 30px;
            margin-bottom: 0;
        }
    }


    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column; 
        padding: 40px 0 0 0;

        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 10px;
        }

        .intro {
            background: var(--color-background);
            padding: 60px 135px;
            margin-top: 60px;
            box-sizing: border-box;
            width: 100%;

            @media (max-width: $tablet) {
                padding-bottom: 0 !important;
            }

            .heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid var(--color-border);
                padding-bottom: 20px;

                @media (max-width: $tablet) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                h2 {
                    text-transform: uppercase;
                    font-size: 1.4rem;
                    letter-spacing: -0.009em;

                    @media (max-width: $tablet) {
                        margin-bottom: 20px;
                    }
                }

                select, .custom-select {
                    width: 300px;
                    max-width: 300px;
                    min-width: 300px;


                    @media (max-width: $tablet) {
                        width: 100%;
                        max-width: 100%;
                        min-width: 100%;
                    }
                }
            }
        }
    }

    .data-map {
        .map-heading {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: 1px solid var(--color-border);
            padding-bottom: 20px;

            @media (max-width: $tablet) {
                padding: 30px;
            }

            h2 {
                text-transform: uppercase;
                font-size: 1.4rem;
                letter-spacing: -0.009em;
                width: 70%;

                @media (max-width: $tablet) {
                    width: 80%;
                }
            }
        }

        .map-controls {
            @media (max-width: $tablet) {
                width: 33px;
            }
        }

        .map {
            height: 800px;
            position: relative;

            .key {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 100;
                display: flex;
                flex-direction: column;
                padding: 80px 40px;

                h3 {
                    font-size: .8rem;
                    color: var(--color-text);
                    font-weight: 400;
                    margin: 0 0 10px 0;
                    letter-spacing: -0.03em;
                    font-family: astoria-sans-condensed, sans-serif;
                }

                ul {
                    margin-top: 10px;

                    li {
                        display: flex;
                        margin-bottom: 5px;
                        align-items: center;

                        span {
                            display: block;
                            margin-right: 10px;
                            width: 20px;
                            height: 20px;
                            background: #303440;
                        }

                        &:nth-child(2) {
                            span {
                                background: #565C7A;
                            }
                        }

                        &:nth-child(3) {
                            span {
                                background: #7D9CC8;
                            }
                        }

                        &:nth-child(4) {
                            span {
                                background: #C6E1ED;
                            }
                        }

                        &:nth-child(5) {
                            span {
                                background: #FFE085;
                            }
                        }

                        &:last-child {
                            span {
                                background: #ececec;
                            }
                        }

                        
                    }
                }
            }

            .popup {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 100;
                display: none;
                pointer-events: none;
                //background: rgba(255,255,255,.8);
                padding: 10px;
                border-radius: 10px;
            

                h2 {
                    text-transform: uppercase;
                    font-size: 1.4rem;
                    letter-spacing: -0.009em;
                    font-family: astoria-sans-condensed, sans-serif;
                    padding-left: 20px;
                    
                }

                span {
                    text-transform: uppercase;
                    font-family: futura-pt, sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 7rem;
                    line-height: 0.7em;
                    letter-spacing: -20px;
                }

                label {
                    text-transform: uppercase;
                    font-size: 1rem;
                    letter-spacing: -0.009em;
                    font-family: astoria-sans-condensed, sans-serif;
                    padding-left: 10px;
                }

                &.active {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

}

.stats-banner {
    padding: 70px 0;
    background: $buttonPrimary;

    @media (max-width: $tablet) {
        padding: 30px;
    }


    h2 {
        text-transform: uppercase;
        color: #2C3136;
        font-size: 28px;
        line-height: 1em;
        font-weight: 300;
        margin-bottom: 20px;
        letter-spacing: -0.01em;
        border-bottom: 1px solid #2C3136;
        padding-bottom: 20px;
        margin-bottom: 30px;

        @media (max-width: $tablet) {
            br { display: none; }
        }
    }

    .details {
        .inner {
            display: flex;
            max-width: $siteWidth;

            @media (max-width: $tablet) {
                flex-direction: column;
            }
        }

        label {
            font-size: 1rem;
            text-transform: uppercase;
            color: #2C3136;
            font-weight: 400;
            margin: 0 0 0 0;
        }

        span {
            font-family: futura-pt, sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 7rem;
            letter-spacing: -0.06em;
            padding: 0px;
            line-height: 0.9em;
            display: block;
            color: white;
        }

        em {
            text-transform: uppercase;
            font-size: 1rem;
            letter-spacing: -0.009em;
            width: 70%;
            color: #2C3136;
        }

        .left {
            width: 30%;
            display: flex;
            flex-direction: column;

            @media (max-width: $tablet) {
                width: 100%;
            }

            span {
                @media (max-width: $tablet) {
                    padding-bottom: 20px;
                }
            }
        }

        .right {
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @media (max-width: $tablet) {
                width: 100%;
                flex-direction: column;
            }

            span {
                @media (max-width: $tablet) {
                    padding: 20px 0 0 0;
                }
            }

            label {
                @media (max-width: $tablet) {
                    padding-top: 20px;
                }
            }

            div {
                width: 48%;
                display: flex;
                flex-direction: column;

                @media (max-width: $tablet) {
                    width: 100%;
                }
            }
        }

        .bottomStat {
            border-top: 1px solid #2C3136;
            margin-top: 15px;
            padding-top: 15px;

            @media (max-width: $tablet) {
                width: 100%;
                border: none;
            }
        }

        h1 {
            height: 1px;
            border-bottom: 1px solid var(--color-border);
        }
    }
}

.stats-banner.services {
        svg {
           transform: scale(0.8);
           margin-bottom: 10px;
        }

        .left {
            width: 100%;
            display: flex;
            flex-direction: row;

            @media (max-width: $tablet) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            div {
                min-width: 300px;

                @media (max-width: $tablet) {
                    min-width: 36%;
                }
            }
        }
}

.page-fragment-issue {
    background: $backgroundPanelColor;
    
    .inner {
        max-width: $siteWidth;
        width: 100%;
        margin: auto;
        display: flex;

        div {
            width: 50%;
        }
    }

    .fragment-issue-header {
        height: auto !important;
        flex-direction: column;
        display: flex;

        .inner {
            padding:5% 0;
        }

        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 40px;
            letter-spacing: -0.01em;
        }

        select {
            display: block;
        }
    }

    .issue-index {
        max-width: $siteWidth;
        margin: auto;

        h2 {
            margin: 0 0 20px 0;
        }

        article {
            display: flex;

            div {
                width: 50%;

                img { 
                    max-width: 100%;
                }
            }
        }
    }
}

.article-recommended {
    .card {
        width: 33%;
    }
}

.news-banner {
    background: var(--color-secondaryBackground);
    padding: 70px 0;
    
    .inner {
        max-width: $siteWidth;
        margin: auto;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 2.5rem;
            color: var(--color-text);
            text-transform: uppercase;
            letter-spacing: 0.03em;
            line-height: 1em;
            margin-bottom: 40px;
            letter-spacing: -0.01em;
        }

        

       
    }    
}

.chart {
    @media (max-width: $tablet) {
        padding: 30px;
    }
    .inner {
        max-width: $siteWidth;
        margin: auto;

        @media (max-width: $tablet) {
            max-height: 800px;
            padding-bottom: 160px;
        }

        h2.heading {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 28px;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 20px;
            letter-spacing: -0.01em;
            border-bottom: 1px solid var(--color-border);
            padding-bottom: 20px;
            margin-bottom: 30px;
            margin-top: 70px;

            @media (max-width: $tablet) {
                margin-top: 30px;
            }
        }
    }

    padding-bottom: 70px;
}