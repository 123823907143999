footer {
    display: flex;
    justify-content: center;
    padding: 70px 0;
    background: var(--color-secondaryBackground);
    border-top: 10px solid #FFD256;
    overflow: hidden;

    @media (max-width: $tablet) {
       padding: 40px 30px;
    }

    .footer-logo {
        @media (max-width: $tablet) {
            display: none;
        }
    }
    
    ul {
        display: flex;
        max-width: $siteWidth;
        justify-content: space-between;

        @media (max-width: $tablet) {
            flex-direction: column;
            position: relative;
         }

        li {
         
            &:first-child {
                min-width: 140px;

                a {
                    display: block;
                    height: 100%;
                }

                img {
                    max-width: 140px;
                    width: 100%;
                }
            }

            &.links-right {
                @media (max-width: $tablet) {
                    position: absolute;
                    top: 0;
                    right: 40%;
                }
            }

            &.links {
                a {
                    font-size: 0.75rem;
                    display: block;
                    text-transform: uppercase;
                    margin: 0 0 10px 0;
                    letter-spacing: $letterSpacing;
                    font-weight: 400;
                    text-decoration: none;
                    color: var(--color-text);

                    @media (max-width: $tablet) {
                        font-size: .9em;
                     }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            &.subscribe {
                width: 25%;

                @media (max-width: $tablet) {
                    width: 100%;
                    margin: 30px 0 50px 0;
                 }

                p {
                    margin: 0 0 20px 0;
                    font-weight: 300;
                    font-style: normal;
                    opacity: .6;
                }
            }

            &.legal {
                display: flex;
                flex-direction: column-reverse;
                font-size: 14px;
                font-weight: 300;
                font-style: normal;

                path {
                    fill: var(--color-fill-icon);
                }
                

                p {
                    font-size: 14px;
                    opacity: .6;
                }

                a {
                    text-decoration: none;
                    color: var(--color-text);
                }
            }

            h3 {
                font-size: 1rem;
                text-transform: uppercase;
                color: var(--color-text);
                font-weight: 400;
                margin: 0 0 10px 0;

                @media (max-width: $tablet) {
                    font-size: 1.2em;    
                }
            }
        }
    }
}