
.page-about{
    
    .header-wrapper {
        background: var(--color-background-panel);
        width: 100%;
        max-width: $siteWidth;
        margin: auto;
        padding: 40px;

        h1 {
            margin: 0 !important;
            padding: 0;

            @media (max-width: $tablet) {
                padding: 0 !important;
            }
        }
    }

    .callout {
        background: #FFD256;
        padding: 30px;
    }

    .intro-img {
        background-image: url('https://ewm.ams3.digitaloceanspaces.com/ewm/about_0879cff3e5.png');
        background-repeat: no-repeat;
        width: 100%;
        padding-top: 100%;
        background-size: cover;
    }

    .image-wrapper {
        width: 100%;
        position: absolute;
        top: 170px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        z-index: 88;

        @media (max-width: $tablet) {
            display: none;
        }

         .image-container {
            background-image: url('https://ewm.ams3.digitaloceanspaces.com/ewm/about_0879cff3e5.png');
            background-repeat: no-repeat;
            width: 50%;
            padding-top: 50vh;
            background-size: cover;
            background-position: center;

            div {
                position: absolute;
                width: 440px;
                height: auto;
                background: #FFD256;
                padding: 30px;
                bottom: -100px;

                p, a {
                    color: #2C3136;
                }
            }
         }
    }
   

    .inner {
        max-width: $siteWidth;
        margin: auto;

        &:after {
            content: ' ';
            display: block;
            clear: both;
        }
    }

    .about-content {
        width: 100%;
        background: var(--color-background);
    }

    .left {
        padding: 40px;
        width: 40%;
        float: left;

        @media (max-width: $tablet) {
            width: 100%;
            padding: 30px;
            padding-bottom: 0;
        }
    }

    .right {
        width: 50%;
        float: left;
        z-index: 99;
        position: relative;
        height: 70vh;
        display: flex;
        justify-content: flex-end;
    }
   
    .header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column; 
        padding: 40px 0 0 0;

        @media (max-width: $tablet) {
            align-items: center;
            padding: 0;
        }


        h1 {
            text-transform: uppercase;
            color: var(--color-text);
            font-size: 2.5rem;
            line-height: 1em;
            font-weight: 300;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            
            span {
                &.flag {
                    width: 150px;
                }
                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        .intro {
            background: var(--color-background);
            padding: 60px 135px;
            margin-top: 60px;
            box-sizing: border-box;
            max-width: $siteWidth;
            width: 100%;
        }
    }

 
   
}
