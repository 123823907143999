.mobile-nav {
    position: fixed;
    background: var(--color-background-panel);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 30px;
    display: none;
    transform: translateX(-100%);
    transition: 0.4s ease;

    .mobile-close-stroke {
        stroke: var(--color-fill-icon);
    }

    &.open {
        transform: translateX(0%);
        transition: 0.4s ease;
    }

    @media (max-width: $tablet) {
        display: flex;
        flex-direction: column;
    }

    a.mobile-close {
        position: absolute;
        width: 21px;
        height: 21px;
        z-index: 999;
        right: 30px;
        top: 30px;

        &:hover {
            opacity: 0.5;
        }

        svg {
            width: 31px;
            height: 31px;
        }
    }

    a {
        color: var(--color-text);
        text-decoration: none;
        font-size: 2em;
        border-bottom: 1px solid var(--color-border);
        margin-bottom: 3px;
        display: block;
        padding: 8px 0;
        text-transform: uppercase;
    }

    .mobile-nav-logo {
        margin-bottom: 40px;
        border: none;
    }

    ul {
        width: 100%;

        li {
            a {
                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}