@import './style/variables.scss';
@import './style/reset.scss';
@import './style/typography.scss';
@import './style/buttons.scss';
@import './style/forms.scss';
@import './style/navigation.scss';
@import './style/carousel.scss';
@import './style/stats.scss';
@import './style/mailinglist.scss';
@import './style/footer.scss';
@import './style/mobilenav';

@import './style/pages/basePage';
@import './style/pages/home';
@import './style/pages/fragments';
@import './style/pages/statePositions';
@import './style/pages/resources';
@import './style/pages/data';
@import './style/pages/subscribe';
@import './style/pages/team';
@import './style/pages/about';
@import './style/pages/privacy';
@import './style/pages/reports';
@import './style/pages/search';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
    font-family: astoria-sans-condensed, sans-serif;
    box-sizing: border-box;
}

@media screen and (orientation:landscape)
    and (min-device-width: 319px) 
    and (max-device-width: $mobile) {
        .site-wrapper {
            display: none;
        }
}

.mob {
    display: none !important;
    @media (max-width: $tablet) {
        display: block !important;
    }
}

.notmob {
    display: block;
    @media (max-width: $tablet) {
        display: none !important;
    }
}

.tabletPadding {
    @media (min-width:$tablet) and (max-width:$siteWidth) {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

@media (min-width: $tablet) {
    :root {
        //font-size: calc(100vw / 1920 * 10);
        font-size: calc(1rem + ((1vw - 7.68px) * 0.3944));
        min-height: 0vw;
    }
}

@media (min-width: $desktop) {
    :root {
        font-size: 20px;
    }
}

html {

    //navigation
    --color-nav-background: white;
    --color-nav-link: #CAD1E1;
    --opacity-nav-link: 1;
    --color-nav-link-active: #2C3136;

    // text
    --color-text-nav: #2C3136;
    --color-text: #2C3136;

    //backgrounds
    --color-background: #fff;
    --color-background-panel: #F6F7FA;
    --color-background-intro: #fff;
    --color-secondaryBackground: #F6F7FA;
    --color-cardBackground: #fff;
    --color-gradientA: rgb(246, 247, 250);
    --color-gradientB: rgb(255, 255, 255);
    --color-background-fragment-issues: #fff;
    --color-background-issue-odd: #F6F7FA;
    --color-background-country-flag: #F6F7FA;

    //borders
    --color-border: #e8e8e8;

    //icons
    --color-fill-icon: #2C3136;

    //forms
    --color-background-input: #fff;
    --color-border-input: #caced1;

    //preloader
    --color-preloader-background: #f6f6f6;

    // cards
    --color-background-resource-card: #F6F7FA;
    --color-background-fragment-card: #F6F7FA;
    --color-background-home-card: #fff;
    --color-background-fragment-article-card: #fff;

    --color-sun-fill: #2C3136;
    --color-sun-stroke: none;

    --color-moon-fill: none;
    --color-moon-stroke: #2C3136;

    background-color: var(--color-background-panel);
}


html[data-theme="dark"] {

    //navigation
    --color-nav-background: black;
    --color-nav-link: #CAD1E1;
    --opacity-nav-link: .5;
    --color-nav-link-active: white;

    //text
    --color-text-nav: #ffffff;
    --color-text: #fff;

    //background
    --color-background: #2C3136;
    --color-background-panel: #000000;
    --color-background-intro: #000;
    --color-secondaryBackground: #000;
    --color-cardBackground: rgb(44, 49, 54);
    --color-gradientA: rgb(0 0 0);
    --color-gradientB: rgb(44, 49, 54);
    --color-background-fragment-issues: #2C3136;
    --color-background-issue-odd: #1f2125;
    --color-background-country-flag: #3f464f;

    //icons
    --color-fill-icon: #fff;

    //borders
    --color-border: #4b4b4b;

    //forms
    --color-background-input: #292d33;
    --color-border-input: #515151;

    //preloader
    --color-preloader-background: #000000;

    // cards
    --color-background-resource-card: #505559;
    --color-background-fragment-card: #505559;
    --color-background-home-card: rgb(44, 49, 54);
    --color-background-fragment-article-card: rgb(44, 49, 54);

    --color-sun-fill: none;
    --color-sun-stroke: #fff;

    --color-moon-fill: white;
    --color-moon-stroke: none;

    background-color: var(--color-background-panel);
}


body {
    margin: 0;
    padding: 0;
    font-family: astoria-sans-condensed, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: var(--color-text);
    background-color: var(--color-background-panel);
    overflow-x: hidden;
}

.json {
    display: none;
}

.site-container {
    padding-top: 130px;
}


.site-wrapper {
    transition: opacity .1s ease;
    background: var(--color-background-panel);
    
    &.is-leaving {
        pointer-events: none;
        opacity: 0;
    }
}

.clear {
    clear: both;
    display: block;
}

.preloader {
    position: fixed;
    top: 0;
    bottom:0;
    left: 0;
    right: 100vw;
    background: var(--color-preloader-background);
    z-index: 1090;
    transition: opacity .1s ease;
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;

    img {
        width: 55px;
        height: 55px;
    }

    @media screen and (orientation:landscape)
    and (min-device-width: 319px) 
    and (max-device-width: $mobile) {
        display: none;
    }
}


.card-image {
    padding-top: 70%;
    background-size: cover;
    background-position: bottom;
    position: relative;

    a.background-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        display: block;
    }
}